<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        :color="(notification_len>0 ? 'error' : 'secondary')"
        overlap
        offset-x="10"
        offset-y="20"
        class="ms-4"
        dot
      >
        <v-icon v-bind="attrs" v-on="on">
          {{ mdiBellOutline }}
        </v-icon>
      </v-badge>
    </template>

    <v-list v-if="notification_len>0" subheader dense twoLine nav class="pt-2">
      <template v-for="(item, index) in notifications">
        <v-list-item :key="item.id" style="height:3.2rem;">
          <v-list-item-avatar>
            <v-img :src="item.photo"></v-img>
          </v-list-item-avatar>

          <v-list-item-content @click="readNotification(index)">
            <router-link :to="getLink(item)">
              <v-list-item-title v-text="item.sender_name"></v-list-item-title>
              <v-list-item-subtitle
                class="text--primary"
                v-text="item.message"
              ></v-list-item-subtitle>
            </router-link>
          </v-list-item-content>

          <v-list-item-action class="mt-1">
            <v-list-item-action-text
              class="pb-1"
              v-text="item.created_at">
            </v-list-item-action-text>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="(item.status=='unread'?'error':'secondary')"
                  @click="readNotification(index)"
                >{{mdiBellOutline}}</v-icon>
              </template>
              <span>Read</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>

        <!-- <v-divider
          v-if="index < notification_len - 1"
          :key="index"
        ></v-divider> -->
      </template>
    </v-list>

  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
import { LOAD_NOTIFICATIONS, READ_NOTIFICATION } from "@/store/_actiontypes";
import { mdiBellOutline, mdiMessageOutline } from '@mdi/js'
import { howLongAgo } from "@/utils/dates"

export default {
  data(){
    return {
      howLongAgo,
      mdiBellOutline,
      mdiMessageOutline,
      polling: null,
      notification_len: 0,
      notifications: [],
    }
  },

  created(){
    this.getNotifications()
  },

  methods: {
    ...mapActions("notification", [LOAD_NOTIFICATIONS, READ_NOTIFICATION]),
    
    getNotifications(){
      this.polling = setInterval(() => {
        let notifications = {};
        this.LOAD_NOTIFICATIONS().then((res)=> {
          if(res.status == 201){
            res.data.items.forEach(item => {
              item["created_at"] = this.howLongAgo(item["created_at"]);
              item["photo"] = item["photo"]
                ? this.$tools.getEnv("VUE_APP_FILE_SERVER") + item["photo"]
                : this.$tools.defaultImage();
                notifications[item["id"]] = item
            });
          }
          this.notification_len = Object.keys(notifications).length;
          this.notifications = notifications;
        }).catch(()=>{});
      }, 10000)
    },

    readNotification(id){
      delete this.notifications[id];
      this.notification_len = Object.keys(this.notifications).length;

      this.READ_NOTIFICATION({id: id}).then((res)=>{
        console.log("read")
      }).catch(()=>{});
    },

    getLink(item){
      let url = "#";
      let type = item.msg_type.split("|");
      
      if(type.length==3 && type[0]=='quotaion'){
        url = '/quotation-detail?uuid='+type[2];
      }
      return url;
    }
  },

  beforeDestroy(){
    clearInterval(this.polling)
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
